import Storage from "@/util/Storage"
import {findFriendCircle} from "@/Api/Friend";
import {MyFriendCircleStore} from "@/views/Friend/MyFriendCircle/Store";


export function findMyFriendsCircle() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(findFriendCircle({ userId,token },MyFriendCircleStore.getMyCircleUpData).then(res=>{
        return res.data
    }))
}
