


import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import MyFriendCircleStoreType from "@/views/Friend/MyFriendCircle/Store/indexType";
import {ItemType} from "@/views/Friend/FriendCircle/Store/indexType";
import {myFriendsCircleUpDataType} from "@/Api/Friend/interfaceType";

@Module({
    name:"MyFriendCircle",
    store,
    dynamic: true,
})
export default class MyFriendCircle extends VuexModule implements MyFriendCircleStoreType{
    myCircleList: ItemType[] = [];
    get getMyCircleList(){ return this.myCircleList }
    @Mutation
    setMyCircleList(data: ItemType[]): void {
        this.myCircleList = data
    }

    myCircleUpData: myFriendsCircleUpDataType ={
        pageNo:1,
        pageSize:15,
        userId:""
    };
    get getMyCircleUpData(){ return this.myCircleUpData }
    @Mutation
    setMyCircleUpData(data: myFriendsCircleUpDataType): void {
        this.myCircleUpData = data
    }

}

export const MyFriendCircleStore = getModule( MyFriendCircle )
